@font-face {
font-family: '__gothamSerifPrice_5fe6d7';
src: url(/_next/static/media/410b1430dd3b8d57-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__gothamSerifPrice_Fallback_5fe6d7';src: local("Arial");ascent-override: 80.00%;descent-override: 20.00%;line-gap-override: 7.50%;size-adjust: 100.00%
}.__className_5fe6d7 {font-family: '__gothamSerifPrice_5fe6d7', '__gothamSerifPrice_Fallback_5fe6d7';font-weight: 500;font-style: normal
}.__variable_5fe6d7 {--font-gothamSerif-price: '__gothamSerifPrice_5fe6d7', '__gothamSerifPrice_Fallback_5fe6d7'
}

@font-face {
font-family: '__gothamSerifBold_b41963';
src: url(/_next/static/media/e6296a3bc93192c0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__gothamSerifBold_Fallback_b41963';src: local("Arial");ascent-override: 65.93%;descent-override: 16.48%;line-gap-override: 6.18%;size-adjust: 121.33%
}.__className_b41963 {font-family: '__gothamSerifBold_b41963', '__gothamSerifBold_Fallback_b41963';font-weight: 700;font-style: normal
}.__variable_b41963 {--font-gothamSerif-bold: '__gothamSerifBold_b41963', '__gothamSerifBold_Fallback_b41963'
}

@font-face {
font-family: '__gothamMedium_d7eab4';
src: url(/_next/static/media/a57ccd246d10211b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__gothamMedium_Fallback_d7eab4';src: local("Arial");ascent-override: 80.11%;descent-override: 14.34%;line-gap-override: 0.00%;size-adjust: 115.09%
}.__className_d7eab4 {font-family: '__gothamMedium_d7eab4', '__gothamMedium_Fallback_d7eab4';font-weight: 500;font-style: normal
}.__variable_d7eab4 {--font-gotham-medium: '__gothamMedium_d7eab4', '__gothamMedium_Fallback_d7eab4'
}

@font-face {
font-family: '__gothamBook_1ef194';
src: url(/_next/static/media/36d00bbd39c0d009-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__gothamBook_Fallback_1ef194';src: local("Arial");ascent-override: 70.47%;descent-override: 17.62%;line-gap-override: 6.61%;size-adjust: 113.52%
}.__className_1ef194 {font-family: '__gothamBook_1ef194', '__gothamBook_Fallback_1ef194';font-weight: 400;font-style: normal
}.__variable_1ef194 {--font-gotham-book: '__gothamBook_1ef194', '__gothamBook_Fallback_1ef194'
}

